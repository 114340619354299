<template>
    <user-profile-section class="mb-8">
        <v-card flat class="transparent">
            <v-card-title class="accent--text text--darken-3">
                {{ $t("Profile.publicSection.otherInfo.title") }}
            </v-card-title>
            <v-card-text class="pb-0">
                <v-text-field
                    v-model="address"
                    v-bind="textFieldClasses"
                    :label="$t('Profile.publicSection.otherInfo.address')"
                ></v-text-field>

                <v-text-field
                    v-model="postal_code"
                    v-bind="textFieldClasses"
                    hint="optional"
                    :label="$t('Profile.publicSection.otherInfo.postal_code')"
                ></v-text-field>
                <select-input
                    :input-value.sync="country"
                    :current-value="country"
                    key-value="name"
                    :multiple="false"
                    :items="countries"
                    :label="$t('Profile.publicSection.otherInfo.country')"
                >
                </select-input>

                <v-card-actions class="justify-between pr-0">
                    <div class="flex-grow-1">
                        <ul v-for="(err, idx) in apiData.errors" :key="idx">
                            <li class="accent--text text--darken-2">
                                {{ err }}
                            </li>
                        </ul>
                    </div>
                    <div
                        v-if="apiData.successMessage"
                        class="flex-grow-1 primary--text"
                    >
                        <span>{{ apiData.successMessage }}</span>
                    </div>
                    <v-btn
                        depressed
                        color="primary"
                        class="submit-button-hover"
                        :loading="apiData.pending"
                        @click="updateMemberWebsite()"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card-text>
        </v-card>
    </user-profile-section>
</template>

<script>
import UserProfileSection from "../userProfile/UserProfileSection.vue";
import { mapGetters } from "vuex";

import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";
import { getCountries } from "../../api/formValuesApi.js";

import SelectInput from "../../components/Inputs/SelectInput.vue";

export default {
    name: "PublicProfileOtherInfo",

    props: {
        info: {
            type: Object,
            required: false,
            default: null,
        },
        textFieldClasses: {
            type: Object,
            required: true,
        },
        apiData: {
            type: Object,
            required: false,
            default: null,
        },
    },

    components: {
        UserProfileSection,
        SelectInput,
    },
    data() {
        return {
            address: this.info.address || null,
            postal_code: this.info.postal_code || null,
            country: this.info.country.id || null,

            countries: [],
        };
    },
    computed: {
        ...apiStatusComputed(["getCountriesStatus"]),
        ...mapGetters({
            userRole: "auth/getUserRole",
        }),
    },
    methods: {
        updateMemberWebsite() {
            const emitData = {
                address: this.address,
                postal_code: this.postal_code,
                country: this.country,
            };
            this.$emit("update-user", emitData);
        },
        async fetchCountries() {
            this.getCountriesStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getCountries);

            if (error) {
                this.getCountriesStatus = apiStatus.Error;
                return;
            }

            this.countries = response.data.countries;

            this.getCountriesStatus = apiStatus.Success;
        },
    },

    created() {
        this.fetchCountries();
    },
};
</script>

<style scoped>
.submit-button-hover:hover {
    background: #c35272 !important;
    transition: 0.1s ease-in;
}
</style>
