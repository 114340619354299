<template>
    <v-row>
        <v-col order="2" order-lg="0">
            <v-text-field
                v-model="oldPassword"
                :error-messages="oldErrors"
                v-bind="textFieldClasses"
                label="Old password"
                autocomplete="new-password"
                :type="showOld ? 'text' : 'password'"
                :append-icon="showOld ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="togglePassword('showOld')"
                @input="$v.oldPassword.$touch()"
                @blur="$v.oldPassword.$touch()"
            ></v-text-field>
            <v-text-field
                v-model="newPassword"
                :error-messages="newErrors"
                v-bind="textFieldClasses"
                label="New password"
                autocomplete="new-password"
                :type="showNew ? 'text' : 'password'"
                :append-icon="showNew ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="togglePassword('showNew')"
                @input="$v.newPassword.$touch()"
                @blur="$v.newPassword.$touch()"
            ></v-text-field>
            <v-text-field
                v-model="confirmNewPassword"
                :error-messages="confirmNewErrors"
                v-bind="textFieldClasses"
                label="Confirm new password"
                autocomplete="new-password"
                :type="showConf ? 'text' : 'password'"
                :append-icon="showConf ? 'fas fa-eye' : 'fas fa-eye-slash'"
                @click:append="togglePassword('showConf')"
                @input="$v.confirmNewPassword.$touch()"
                @blur="$v.confirmNewPassword.$touch()"
            ></v-text-field>
            <v-card-actions class="justify-between pr-0">
                <div class="flex-grow-1">
                    <ul v-for="(err, idx) in errors" :key="idx">
                        <li class="accent--text text--darken-2">{{ err }}</li>
                    </ul>
                </div>
                <div v-if="successMessage" class="flex-grow-1 primary--text">
                    <span>{{ successMessage }}</span>
                </div>
                <v-btn
                    depressed
                    color="primary"
                    class="submit-button-hover"
                    :disabled="$v.$invalid"
                    :loading="updatePasswordStatus_Pending"
                    @click="handleUpdatePassword()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-col>
        <v-col cols="12" lg="4" order="1" order-lg="0" align-self="start">
            <v-alert text icon="fas fa-info" class="text-body-2">
                {{ $t("Profile.passwordSection.info") }}
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
import { withAsync } from "../../helpers/withAsync";
import { updatePassword } from "@/api/userApi.js";
import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";

import { requiredIf, sameAs, minLength } from "vuelidate/lib/validators";

import { createErrorsArray } from "../../helpers/general";
export default {
    name: "UpdatePassword",

    validations: {
        oldPassword: {
            required: requiredIf((val) => !!val.newPassword),
        },
        confirmNewPassword: {
            required: requiredIf((val) => !!val.newPassword),
            sameAsNew: sameAs("newPassword"),
        },
        newPassword: {
            required: requiredIf(
                (val) => !!val.oldPassword || !!val.confirmNewPassword
            ),
            minLength: minLength(3),
        },
    },

    props: {
        textFieldClasses: {
            type: Object,
            required: true,
        },
    },
    components: {},
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",

            showOld: false,
            showNew: false,
            showConf: false,

            timesClicked: 0,

            updatePasswordStatus: apiStatus.Idle,

            errors: [],
            successMessage: "",
        };
    },

    computed: {
        ...apiStatusComputed(["updatePasswordStatus"]),
        oldErrors() {
            const errors = [];
            if (!this.$v.oldPassword.$dirty) return errors;
            !this.$v.oldPassword.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            return errors;
        },
        newErrors() {
            const errors = [];
            if (!this.$v.newPassword.$dirty) return errors;
            !this.$v.newPassword.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            !this.$v.newPassword.minLength &&
                errors.push(this.$i18n.tc(`ErrorMessages.minLength`, 3));
            return errors;
        },
        confirmNewErrors() {
            const errors = [];
            if (!this.$v.confirmNewPassword.$dirty) return errors;
            !this.$v.confirmNewPassword.required &&
                errors.push(this.$i18n.t(`ErrorMessages.required`));
            !this.$v.confirmNewPassword.sameAsNew &&
                errors.push(this.$i18n.t(`ErrorMessages.sameAsNewPassword`));
            return errors;
        },
    },

    methods: {
        async handleUpdatePassword() {
            if (!this.oldPassword) return;

            this.updatePasswordStatus = apiStatus.Pending;

            const payload = {
                old_password: this.oldPassword,
                password: this.newPassword,
                password_confirmation: this.confirmNewPassword,
            };

            const { response, error } = await withAsync(
                updatePassword,
                payload
            );
            if (error) {
                this.updatePasswordStatus = apiStatus.Error;
                const errorsArray = createErrorsArray(
                    error.response.data.errors
                );

                this.errors = errorsArray;
                return;
            }

            this.errors = [];
            this.successMessage = response.data.message;
            setTimeout(() => {
                this.successMessage = "";
            }, 3000);

            this.oldPassword = "";
            this.newPassword = "";
            this.confirmNewPassword = "";

            this.updatePasswordStatus = apiStatus.Success;
        },

        togglePassword(field) {
            this[field] = !this[field];
        },
    },

    created() {},
};
</script>

<style scoped>
.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}
</style>
