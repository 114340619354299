<template>
    <v-container class="d-md-flex my-10">
        <div class="d-md-flex">
            <v-tabs fixed-tabs center-active class="d-md-none">
                <v-tab
                    v-for="(item, idx) in sections"
                    :key="idx"
                    link
                    exact
                    class="pa-0"
                    style="max-width: 40px"
                    :to="setRedirect(item)"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon dark v-bind="attrs" v-on="on">
                                {{ item.icon }}
                            </v-icon>
                        </template>
                        <span>{{ item.label }}</span>
                    </v-tooltip>
                </v-tab>
            </v-tabs>
            <v-navigation-drawer
                permanent
                expand-on-hover
                style="min-width: 60px; max-height: 300px !important"
                class="d-none d-md-block"
            >
                <v-list nav dense>
                    <v-list-item-group v-model="activeSection" color="primary">
                        <div>
                            <v-list-item
                                v-for="(item, idx) in sections"
                                :key="idx"
                                link
                                exact
                                :to="setRedirect(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ item.label }}
                                </v-list-item-title>
                            </v-list-item>
                        </div>
                        <v-list-item
                         v-if="userRole != 0"
                         link color="error"
                         @click="handleLogout()"
                        >
                          <v-list-item-icon>
                             <v-icon color="error">
                               fas fa-right-from-bracket
                              </v-icon>
                          </v-list-item-icon>
                        <v-list-item-title>Logout</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        </div>
        <div v-if="getUserStatus_Success" class="flex-grow-1">
            <keep-alive>
                <router-view
                    :key="routeKey"
                    :user="computedProps"
                    :user-role="computedRole"
                ></router-view>
            </keep-alive>
        </div>
        <div v-else class="flex-grow-1" style="min-height: 400px">
            <loading-spinner></loading-spinner>
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";
import { getUserProfile } from "@/api/userApi.js";
import { logoutUser } from "../../api/authApi.js";

import LoadingSpinner from "@/components/LoadingSpinner.vue";

const SECTIONS = [
    {
        label: "My Profile",
        icon: "fas fa-user",
        to: "UserProfile",
    },

    // {
    //     label: "Favourites",
    //     icon: "fas fa-star",
    //     to: "UserProfileFavorites",
    // },
    // {
    //     label: "Help",
    //     icon: "fas fa-question",
    //     to: "UserProfileHelp",
    // },
];
export default {
    name: "UserProfile",

    props: {
        id: {
            required: false,
            type: [Number, String],
            default: null,
        },
    },
    components: { LoadingSpinner },
    data() {
        //
        return {
            user: {
                edit: {},
                public: {},
            },
            sections: SECTIONS,
            activeSection: 0,
            determineId: null,
            getUserStatus: apiStatus.Idle,
            logoutStatus: apiStatus.Idle,
        };
    },
    computed: {
        ...mapGetters({
            userRole: "auth/getUserRole",
            userId: "auth/getUserId",
        }),
        ...apiStatusComputed(["getUserStatus", "logoutStatus"]),
        routeKey() {
            return this.$route.path;
        },

        computedProps() {
            if (this.$route.name === "UserProfile") return this.user.edit;

            if (this.$route.name === "UserProfilePublic")
                return this.user.public;
            if (this.$route.name === "UserProfileFavorites")
                return this.user.favorites;
            if (this.$route.name === "UserProfileHelp") return this.user.help;

            return {};
        },

        computedRole() {
            return this.userRole == 1 ? "DIH" : "SME";
        },
    },
    methods: {
        setRedirect(item) {
            if (item.to)
                return { name: item.to, params: { id: this.determineId } };
            else return "";
        },

        async handleUser() {
            this.getUserStatus = apiStatus.Pending;
            const payload = {
                id: this.determineId,
            };

            const { response, error } = await withAsync(
                getUserProfile,
                payload
            );

            if (error) return (this.getUserStatus = apiStatus.Error);

            const {
                email,
                secondary_email,
                description,
                contact_fullname,
                contact_email,
                contact_phone,
                organization_website,
                smart_specialisation_website,
                skills_training_website,
                picture,
                logo,
                country,
                lat,
                lng,
                skills_training,
                organization_type,
                ecosystem_networking,
                test_before_invest,
                support_to_find_investments,
            } = response.data.data;
            this.user.edit = { id: this.determineId, email, secondary_email };
            this.user.public = {
                id: this.determineId,
                description,
                email,
                contact: {
                    fullname: contact_fullname,
                    email: contact_email,
                    org_website: organization_website,
                    skills_website: skills_training_website,
                    telephone: contact_phone,
                    smart_website: smart_specialisation_website,
                },
                country,
                coords: {
                    lat,
                    lng,
                },
                images: {
                    picture: picture?.path || null,
                    logo: logo?.path || null,
                },
                userType: {
                    id: organization_type.id,
                },
                services: {
                    skills_training,
                    ecosystem_networking,
                    test_before_invest,
                    support_to_find_investments,
                },
            };
            this.getUserStatus = apiStatus.Success;
        },

        /**
         * Sends a call to the backend to logout the user.
         * On success it dispatches 2 vuex store actions (removes token and user role)
         * Then it replaces the current route with login route.
         */

        async handleLogout() {
            this.logoutStatus = apiStatus.Pending;

            const { response, error } = await withAsync(logoutUser);

            if (error) {
                this.logoutStatus = apiStatus.Error;
                return;
            }

            this.logoutStatus = apiStatus.Success;
            this.$store.dispatch("auth/removeToken");
            this.$store.dispatch("auth/removeUserRole");
            this.$store.dispatch("auth/removeUserId");
            this.$router.replace("/login");

            const cookieOptions = {
                domain: ".agrobofood.eu", //need to change with agrobofood subdomain to work with cross-domain cookie
                SameSite: "Strict",
                maxAge: "",
            };

            //Create cookie with wanted values
            document.cookie = `token=;Domain=${cookieOptions.domain};SameSite=${cookieOptions.SameSite};`;
        },
    },
    created() {
        this.id
            ? (this.determineId = this.id)
            : (this.determineId = this.userId);
        this.handleUser();
    },
};
</script>

<style scoped></style>
