<template>
    <div class="pa-4">
        <user-profile-section class="mb-8">
            <v-card flat class="transparent">
                <v-card-title class="accent--text text--darken-3">
                    {{ $t("Profile.userSection.title") }}
                </v-card-title>

                <v-card-text class="pb-0">
                    <v-row>
                        <v-col order="2" order-lg="0">
                            <mail-input
                                :input-value.sync="email"
                                :current-value="email"
                                :in-valid.sync="emailInvalid"
                                label="E-mail Address"
                            ></mail-input>
                            <v-text-field
                                v-model="optional_email"
                                v-bind="textFieldClasses"
                                hint="Optional"
                                label="Additional E-mail Address"
                            ></v-text-field>
                            <v-card-actions class="justify-between pr-0">
                                <div class="flex-grow-1">
                                    <ul v-for="(err, idx) in errors" :key="idx">
                                        <li class="accent--text text--darken-2">
                                            {{ err }}
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    v-if="successMessage"
                                    class="flex-grow-1 primary--text"
                                >
                                    <span>{{ successMessage }}</span>
                                </div>
                                <v-btn
                                    depressed
                                    color="primary"
                                    class="submit-button-hover"
                                    :disabled="toggleSaveButton"
                                    @click="updateEmail()"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-col>
                        <v-col
                            cols="12"
                            lg="4"
                            align-self="start"
                            order="1"
                            order-lg="0"
                        >
                            <v-alert
                                text
                                icon="fas fa-info"
                                class="text-body-2"
                            >
                                {{ $t("Profile.userSection.info") }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card flat class="transparent">
                <v-card-title class="accent--text text--darken-3">
                    {{ $t("Profile.passwordSection.title") }}
                </v-card-title>
                <v-card-text class="pb-0">
                    <update-password
                        :text-field-classes="textFieldClasses"
                    ></update-password>
                </v-card-text>
            </v-card>
        </user-profile-section>

        <user-profile-section v-if="!userIsAdmin">
            <v-card flat class="transparent pa-0">
                <v-card-title class="accent--text text--darken-3">
                    Privacy & Terms
                </v-card-title>
                <v-card-text>
                    <p>
                        You can revisit our Associate Membership Terms anytime
                        <router-link
                            :to="{ name: computedTermsPage }"
                            target="_blank"
                            class="error--text"
                        >
                            here
                        </router-link>
                    </p>
                    <p>
                        You can revisit our Privacy Terms anytime
                        <router-link
                            :to="{ name: 'PrivacyTerms' }"
                            target="_blank"
                            class="error--text"
                        >
                            here
                        </router-link>
                    </p>
                </v-card-text>
                <v-card-title class="error--text text--darken-3">
                    {{ $t(`Profile.deleteSection.title`) }}
                </v-card-title>
                <v-card-text
                    class="accent--text text--darken-3"
                    style="max-width: 700px"
                >
                    <p>
                        {{ $t(`Profile.deleteSection.text`) }}
                    </p>
                    <v-btn
                        color="error"
                        depressed
                        class="delete-button-hover"
                        @click="deleteModalOpen = true"
                    >
                      <v-icon x-small left>fa fa-trash</v-icon>
                        Delete Account
                    </v-btn>
                </v-card-text>
            </v-card>
        </user-profile-section>
        <confirm-modal :open="deleteModalOpen" :close.sync="deleteModalOpen">
            <template #content>
                <span class="modal-content">
                    {{ $t(`Profile.modal.text`) }}
                </span>
            </template>
            <template #action>
                <v-btn
                    depressed
                    dense
                    color="error lighten-1"
                    class="mr-2 delete-button-hover"
                    :loading="false"
                    @click="confirmDelete()"
                >
                    {{ $t(`Profile.modal.confirm`) }}
                </v-btn>
            </template>
        </confirm-modal>
    </div>
</template>

<script>
import { withAsync } from "../../helpers/withAsync";
import { deleteAccount, updateProfile } from "@/api/userApi.js";
import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";

import UserProfileSection from "./UserProfileSection.vue";
import UpdatePassword from "./UserProfileUpdatePassword.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";

import { createErrorsArray } from "../../helpers/general";

import MailInput from '../../components/Inputs/MailInput.vue';

import { mapGetters } from 'vuex';

export default {
    name: "UserProfileEdit",

    props: {
        user: {
            type: Object,
            required: false,
            default: () => {
                noUser: true;
            },
        },

        userRole: {
            type: String,
            required: true,
        },
    },
    components: { UserProfileSection, ConfirmModal, UpdatePassword, MailInput },

    data() {
        return {
            setAccountDeletionStatus: apiStatus.Idle,
            textFieldClasses: {
                color: "accent darken-4",
                dense: true,
                outlined: true,
            },

            email: this.user.email || null,
            optional_email: this.user.secondary_email || null,
            deleteModalOpen: false,

            errors: [],
            successMessage: "",
            emailInvalid: this.user.email ? false : true
        };
    },

    computed: {
        ...apiStatusComputed("setAccountDeletionStatus"),
        ...mapGetters({
            userIsAdmin: "auth/userIsAdmin",
        }),
        computedTermsPage() {
            return this.userRole == "DIH" ? "DihTerms" : "SmeTerms";
        },
        toggleSaveButton() {
            if(this.user) {
                for(let key in this.user) {
                    if(this.user.hasOwnProperty(key)) {
                        return this.isValidated()
                    }
                }
                return true;
            }
            return this.isValidated();
        },
    },
    methods: {
        isValidated() {
            if(this.emailInvalid) return true;
        },
        async confirmDelete() {
            this.setAccountDeletionStatus = apiStatus.Pending;
            const payload = {
                id: this.user.id,
            };

            const { response, error } = await withAsync(deleteAccount, payload);
            if (error) {
                this.setAccountDeletionStatus = apiStatus.Error
                return;
            }

            this.setAccountDeletionStatus = apiStatus.Success;

            this.$store.dispatch("auth/removeToken");
            this.$store.dispatch("auth/removeUserRole");
            this.$store.dispatch("auth/removeUserId");
            this.$router.push({ name: "Login" });
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },

        async updateEmail() {
            const reformedPayload = {
                id: this.user.id,
                data: {email: this.email, secondary_email: this.optional_email}
            };

            const { response, error } = await withAsync(
                updateProfile,
                reformedPayload
            );

            if (error) {
                this.deleteAccountStatus = apiStatus.Error;
                const errorsArray = createErrorsArray(
                    error.response.data.errors
                );

                this.errors = errorsArray;
                return;
            }

            this.errors = [];
            this.successMessage = response.data.message;
            setTimeout(() => {
                this.successMessage = "";
            }, 3000);
        },
    },

    watch: {
        user: {
            handler(newVal) {
                this.email = newVal.email;
            },
        },
    },
};
</script>

<style scoped>
.delete-button-hover:hover {
    background: #33CC33 !important;
    transform: scale(1.03);
}
.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}
</style>
