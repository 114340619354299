<template>
    <v-container v-if="getUserStatus_Success" class="py-12">
        <v-row>
            <v-col cols="12" lg="7">
                <member-details
                    :member-details="user.details"
                    :text-field-classes="textFieldClasses"
                    :api-data="apiData.memberDetails"
                    @update-user="updateUser($event, 'memberDetails')"
                ></member-details>
                <update-coords
                    :coords="user.coords"
                    :api-data="apiData.location"
                    class="my-4"
                    @update-user="updateUser($event, 'location')"
                ></update-coords>
                <other-information
                    :info="user.otherInfo"
                    :text-field-classes="textFieldClasses"
                    :api-data="apiData.otherInfo"
                    @update-user="updateUser($event, 'otherInfo')"
                ></other-information>
            </v-col>

            <!-- Map & Images -->
            <v-col cols="12" lg="5">
                <v-row>
                    <v-col cols="12" md="6" lg="12">
                        <user-profile-section class="mb-8">
                            <user-profile-image-input
                                :old-img="user.images.logo"
                                :api-data="apiData.logo"
                                @upload-image="uploadImage($event, 'logo')"
                            >
                                <template #title>
                                    {{
                                        $t(
                                            "Profile.publicSection.images.logo.title"
                                        )
                                    }}
                                </template>
                                <template #info>
                                    {{
                                        $t(
                                            "Profile.publicSection.images.logo.info"
                                        )
                                    }}
                                </template>
                            </user-profile-image-input>
                        </user-profile-section>
                    </v-col>
                    <v-col cols="12" md="6" lg="12">
                        <user-profile-section class="mb-8">
                            <user-profile-image-input
                                :old-img="user.images.picture"
                                :api-data="apiData.picture"
                                @upload-image="uploadImage($event, 'picture')"
                            >
                                <template #title>
                                    {{
                                        $t(
                                            "Profile.publicSection.images.profile.title"
                                        )
                                    }}
                                </template>
                                <template #info>
                                    {{
                                        $t(
                                            "Profile.publicSection.images.profile.info"
                                        )
                                    }}
                                </template>
                            </user-profile-image-input>
                        </user-profile-section>
                    </v-col>
                </v-row>
            </v-col>

            <!-- Description -->
            <v-col cols="12">
                <user-profile-section>
                    <v-card-title class="accent--text text--darken-3">
                        {{ $t(`Profile.publicSection.details.${user.userType.id == 1 ? 'dihTitle' : 'smeTitle'}`) }}
                    </v-card-title>
                    <v-card-text class="pr-0">
                        <v-textarea
                            v-model="user.description"
                            v-bind="textFieldClasses"
                            rows="10"
                            maxLength="2000"
                            :label="'Description'"
                        ></v-textarea>
                    </v-card-text>
                    <v-card-actions class="justify-between pr-0">
                        <div class="flex-grow-1">
                            <ul
                                v-for="(err, idx) in apiData.description.errors"
                                :key="idx"
                            >
                                <li class="accent--text text--darken-2">
                                    {{ err }}
                                </li>
                            </ul>
                        </div>
                        <div
                            v-if="apiData.description.successMessage"
                            class="flex-grow-1 primary--text"
                        >
                            <span>{{
                                apiData.description.successMessage
                            }}</span>
                        </div>
                        <v-btn
                            depressed
                            color="primary"
                            class="submit-button-hover"
                            :loading="apiData.description.pending"
                            @click="updateDescription('description')"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </user-profile-section>
            </v-col>

            <!-- Services Provided -->
            <v-col cols="12">
                <user-profile-section>
                    <services-provided
                        :services="user.services"
                        :api-data="apiData.services"
                        @update-user="updateUser($event, 'services')"
                    ></services-provided>
                </user-profile-section>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";
import {
    getUserProfile,
    deleteAccount,
    updateProfile,
    uploadFile,
} from "@/api/userApi.js";

import { update_logo_picture } from "@/api/adminApi.js";

import MemberDetails from "./PublicProfileMemberDetails.vue";
import OtherInformation from "./PublicProfileOtherInfo.vue";

import UserProfileSection from "../userProfile/UserProfileSection.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import UserProfileImageInput from "./PublicProfileImageInput.vue";
import UpdateCoords from "./PublicProfileUpdateCoords.vue";
import ServicesProvided from "./PublicProfileServicesProvided.vue";

import { createErrorsArray } from "../../helpers/general";

export default {
    name: "PublicProfile",

    props: {
        id: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    components: {
        UserProfileSection,
        ConfirmModal,
        UserProfileImageInput,
        MemberDetails,
        OtherInformation,
        UpdateCoords,
        ServicesProvided,
    },

    data() {
        return {
            user: null,
            loadingButton: false,
            deleteModalOpen: false,
            textFieldClasses: {
                color: "accent darken-4",
                dense: true,
                outlined: true,
            },

            formParams: {},

            deleteAccountStatus: apiStatus.Idle,
            getUserStatus: apiStatus.Idle,
            imageStatus: apiStatus.Idle,

            apiData: {
                memberDetails: {
                    errors: [],
                    successMessage: "",
                    pending: false,
                },

                description: {
                    errors: [],
                    successMessage: "",
                    pending: false,
                },

                services: {
                    errors: [],
                    successMessage: "",
                    pending: false,
                },

                location: {
                    errors: [],
                    successMessage: "",
                    pending: false,
                },

                logo: {
                    errors: [],
                    successMessage: "",
                    pending: false,
                },

                picture: {
                    errors: [],
                    successMessage: "",
                    pending: false,
                },

                otherInfo: {
                    erros: [],
                    successMessage: "",
                    pending: false,
                },
            },
        };
    },

    computed: {
        ...apiStatusComputed([
            "getUserStatus",
            "deleteAccountStatus",
            "imageStatus",
        ]),
        ...mapGetters({
            userIsAdmin: "auth/userIsAdmin",
            userRole: "auth/getUserRole",
            userId: "auth/getUserId",
        }),

        handleUserId() {
            return this.id || this.userId;
        },
    },
    methods: {
        async handleUser() {
            this.getUserStatus = apiStatus.Pending;
            const payload = {
                id: this.handleUserId,
            };

            const { response, error } = await withAsync(
                getUserProfile,
                payload
            );

            if (error) return (this.getUserStatus = apiStatus.Error);

            const {
                description,
                organization_fullname,
                contact_email,
                contact_phone,
                organization_website,
                smart_specialisation_website,
                skills_training_website,
                picture,
                logo,
                facebook_url,
                youtube_url,
                twitter_url,
                linkedin_url,
                country,
                lat,
                lng,
                address,
                postal_code,
                skills_training,
                organization_type,
                ecosystem_networking,
                test_before_invest,
                innovation_fields,
                technology_fields,
                support_to_find_investments,
            } = response.data.data;
            this.user = {
                description,
                details: {
                    type: organization_type.id,
                    userRole: organization_type.id,
                    fullname: organization_fullname,
                    email: contact_email,
                    org_website: organization_website,
                    skills_website: skills_training_website,
                    telephone: contact_phone,
                    smart_website: smart_specialisation_website,
                    facebook_url,
                    youtube_url,
                    twitter_url,
                    linkedin_url,
                },

                otherInfo: {
                    address,
                    postal_code,
                    country,
                },

                coords: {
                    lat,
                    lng,
                },
                images: {
                    picture: picture?.path || null,
                    logo: logo?.path || null,
                },
                userType: {
                    id: organization_type.id,
                },
                services: {
                    type: organization_type.id,
                    skills_training,
                    ecosystem_networking,
                    test_before_invest,
                    support_to_find_investments,
                    technology_fields,
                    innovation_fields,
                },
            };
            this.getUserStatus = apiStatus.Success;
        },

        determineUserAction(img, type) {
            return this.userIsAdmin
                ? this.adminUpdateUserImage(img, type)
                : this.uploadImage(img, type);
        },

        updateDescription(apiDataType) {
            const payload = {
                description: this.user.description,
            };
            this.updateUser(payload, apiDataType);
        },

        async updateUser(payload, apiDataType) {
            if (apiDataType) this.apiData[apiDataType].pending = true;

            const reformedPayload = {
                id: this.handleUserId,
                data: { ...payload },
            };

            const { response, error } = await withAsync(
                updateProfile,
                reformedPayload
            );

            if (error) {
                const errorsArray = createErrorsArray(
                    error.response.data.errors
                );
                if (apiDataType) {
                    this.apiData[apiDataType].pending = false;
                    this.apiData[apiDataType].errors = errorsArray;
                }
                return;
            }

            if (apiDataType) {
                this.apiData[apiDataType].successMessage =
                    response.data.message;
                setTimeout(() => {
                    this.apiData[apiDataType].successMessage = "";
                }, 3000);
            }
            if (apiDataType) this.apiData[apiDataType].pending = false;
        },

        async uploadImage(img, type) {
            if (type) this.apiData[type].pending = true;

            const data = new FormData();
            data.append(`${type}_file`, img);
            if (this.userIsAdmin) data.append("user_id", this.handleUserId);

            const payload = {
                type,
                data,
            };

            const { response, error } = await withAsync(uploadFile, payload);

            if (error) {
                const errorsArray = createErrorsArray(
                    error.response.data.errors
                );

                this.apiData[type].errors = errorsArray;
                return;
            }

            if (type) {
                this.apiData[type].successMessage = response.data.message;
                setTimeout(() => {
                    this.apiData[type].successMessage = "";
                }, 3000);
            }

            if (type) this.apiData[type].pending = false;
        },
    },

    created() {
        this.handleUser();
    },
};
</script>

<style scoped>
.submit-button-hover:hover {
    background: #c35272 !important;
    transition: 0.1s ease-in;
}
</style>
