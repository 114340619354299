<template>
    <v-card class="shadow" style="background-color: #eeeeeecf">
        <v-card-title
            class="accent--text text--darken-4 d-flex justify-space-between"
        >
            <span text depressed class="text-h6 accent--text text--darken-3">
                Update your location
            </span>
            <v-icon
                color="accent darken-4"
                class="ml-2"
                @click="toggleExpand()"
            >
                {{ `fas fa-chevron-${expanded ? "up" : "down"}` }}
            </v-icon>
        </v-card-title>
        <v-card-text v-if="expanded" class="pa-3">
            <select-location
                :coordinates.sync="coordinates"
                :marker-coords="coordinates"
                :map-options="mapOptions"
                :has-coords.sync="isLocationInvalid"
                @map-ready="sendCoordinates()"
            ></select-location>
        </v-card-text>
        <v-card-actions v-if="expanded" class="pa-3 justify-between">
            <div class="flex-grow-1">
                <ul v-for="(err, idx) in apiData.errors" :key="idx">
                    <li class="accent--text text--darken-2">
                        {{ err }}
                    </li>
                </ul>
            </div>
            <div
                v-if="apiData.successMessage"
                class="flex-grow-1 primary--text"
            >
                <span>{{ apiData.successMessage }}</span>
            </div>
            <v-btn
                depressed
                color="primary"
                class="submit-button-hover"
                :disabled="!coordinates || !coordinates.lat"
                :loading="apiData.pending"
                @click="handleUpdateCoords()"
            >
                Save
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import SelectLocation from "@/components/Inputs/SelectLocation.vue";

export default {
    name: "UpdateCoords",

    props: {
        coords: {
            type: Object,
            required: false,
            default: () => null,
        },

        apiData: {
            type: Object,
            required: false,
            default: null,
        },
    },
    components: { SelectLocation },

    data() {
        return {
            coordinates: this.coords || null,
            mapOptions: {
                zoomControl: false,
                trackResize: true,
                maxBoundsViscosity: 1.0,
                doubleClickZoom: false,
                height: 1,
            },
            isLocationInvalid: true,

            expanded: false,
        };
    },

    methods: {
        async handleUpdateCoords() {
            const { lat, lng } = this.coordinates;
            const payload = {
                lat,
                lng,
            };

            this.$emit("update-user", payload);
        },

        toggleExpand() {
            this.expanded = !this.expanded;

            this.$nextTick(() => {
                this.mapOptions.height = this.expanded ? 600 : 1;
            });
        },

        sendCoordinates() {
            this.coordinates = { ...this.coordinates };
        },
    },

    watch: {
        coords: {
            handler(newVal) {
                this.coordinates = newVal;
            },
        },
    },
};
</script>

<style scoped>
.shadow {
    box-shadow: rgba(22, 22, 22, 0.1) 0px 2px 8px 0px !important;
    /* box-shadow: 0px 0px 20px rgba(10, 52, 67, 0.25) !important; */
}
.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}
</style>
