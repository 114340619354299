<template>
    <div>
        <p class="accent--text text--darken-3 text-h6">
            <slot name="title" />
        </p>

        <upload-input
            :image-url.sync="img"
            :current-image="defaultURL"
            :in-valid.sync="isImageUrlInvalid"
        ></upload-input>

        <div class="">
            <span class="accent--text text--darken-2 text-body-2 flex-grow-1">
                <slot name="info" />
            </span>

            <div class="d-flex justify-between">
                <div class="flex-grow-1">
                    <ul v-for="(err, idx) in apiData.errors" :key="idx">
                        <li class="accent--text text--darken-2">
                            {{ err }}
                        </li>
                    </ul>
                </div>
                <div
                    v-if="apiData.successMessage"
                    class="flex-grow-1 primary--text"
                >
                    <!-- <span>{{ apiData.successMessage }}</span> -->
                    <span>Your profile updated successfully</span>
                </div>

                <v-btn
                    depressed
                    color="primary"
                    class="submit-button-hover"
                    :loading="apiData.pending"
                    :disabled="isImageUrlInvalid"
                    @click="updateImage()"
                >
                    Save
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import UploadInput from "@/components/Inputs/UploadInput.vue";

const defaultAvatar = require("../../assets/Image_not_available.png");

export default {
    name: "UserProfileImageInput",

    props: {
        oldImg: {
            type: String,
            required: false,
            default: null,
        },

        apiData: {
            type: Object,
            required: false,
            default: null,
        },
    },
    components: { UploadInput },

    data() {
        return {
            img: null,
            isImageUrlInvalid: true,

            defaultURL: this.oldImg || defaultAvatar,
        };
    },

    computed: {},
    methods: {
        updateImage() {
            this.$emit("upload-image", this.img);
        },
    },

    created() {},
};
</script>

<style scoped>
.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}
</style>
