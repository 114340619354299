<template>
    <div class="pa-4" style="min-width: 200px;">
        <user-profile-section> </user-profile-section>
    </div>
</template>

<script>
import UserProfileSection from "./UserProfileSection.vue";
export default {
    name: "UserProfileFavorites",

    props: {
        user: {
            type: Object,
            required: false,
            default: () => {
                noUser: true;
            },
        },
    },
    components: { UserProfileSection },

    data() {
        return {};
    },
};
</script>
