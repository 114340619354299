<template>
    <div v-if="formParamsStatus_Success">
        <v-card-title class="accent--text text--darken-3">
            {{ $t(`Profile.publicSection.services.${services.type == 1 ? 'dihTitle' : 'smeTitle'}`) }}
        </v-card-title>
        <v-card-text>
            <v-autocomplete
                v-for="(serviceVal, serviceKey, idx) in computedShownFields"
                :key="idx"
                v-model="servicesSchema[serviceKey].value"
                :items="formParams[serviceVal.items]"
                multiple
                chips
                deletable-chips
                clearable
                item-text="name"
                item-value="id"
                v-bind="textFieldClasses"
                :label="
                    $t(`Profile.publicSection.services.${serviceVal.label}`)
                "
            ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="justify-between pr-0 mt-auto">
            <div class="flex-grow-1">
                <ul v-for="(err, idx) in apiData.errors" :key="idx">
                    <li class="accent--text text--darken-2">
                        {{ err }}
                    </li>
                </ul>
            </div>
            <div
                v-if="apiData.successMessage"
                class="flex-grow-1 primary--text"
            >
                <span>{{ apiData.successMessage }}</span>
            </div>
            <v-btn
                depressed
                color="primary"
                class="submit-button-hover"
                :loading="apiData.pending"
                @click="updateServices()"
            >
                Save
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";

import { getServiceFormParams } from "@/api/userApi.js";

import SelectInput from "@/components/Inputs/SelectInput.vue";

export default {
    name: "UserProfileServicesProvided",

    props: {
        services: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
        apiData: {
            type: Object,
            required: false,
            default: null,
        },
    },

    components: { SelectInput },

    data() {
        return {
            textFieldClasses: {
                color: "accent darken-4",
                dense: true,
                outlined: true,
            },

            servicesSchema: {
                ecosystem_networking: {
                    value: [],
                    label: "innovation",
                    items: "ecosystem_networking",
                },
                test_before_invest: {
                    value: [],
                    label: "test",
                    items: "test_before_invest",
                },
                skills_training: {
                    value: [],
                    label: "skills",
                    items: "skills_training",
                },
                support_to_find_investments: {
                    value: [],
                    label: "support",
                    items: "support_to_find_investments",
                },
                technology_fields: {
                    value: [],
                    label: "technology_fields",
                    items: "technology_fields",
                },
                innovation_fields: {
                    value: [],
                    label: "innovation_fields",
                    items: "innovation_fields",
                },
            },

            formParams: {
                skills_training: [],
                ecosystem_networking: [],
                test_before_invest: [],
                support_to_find_investments: [],
                technology_fields: [],
                innovation_fields: [],
            },

            formParamsStatus: apiStatus.Idle,
        };
    },

    computed: {
        ...apiStatusComputed(["formParamsStatus"]),

        computedShownFields(){
            return Object.entries(this.servicesSchema).reduce((acc, [key, value]) => {
                if(this.services.type == 2){
                    if(key === 'technology_fields' || key === 'innovation_fields') {
                        acc[key] = value;
                    }
                    return acc
                } else {
                    acc[key] = value;
                    return acc
                }
            }, {})
        }
    },

    methods: {
        async getFormParams() {
            this.formParamsStatus = apiStatus.Pending;

            const { response, error } = await withAsync(getServiceFormParams);

            if (error) {
                return (this.formParamsStatus = apiStatus.Error);
            }

            const params = response.data.data;

            Object.entries(this.formParams).forEach(([key]) => {
                this.formParams[key] = params[key];
            });

            this.formParamsStatus = apiStatus.Success;
        },
        updateServices() {
            const servicesSelected = {};
            Object.entries(this.servicesSchema).forEach(([key, val]) => {
                servicesSelected[key] = val.value;
            });

            this.$emit("update-user", servicesSelected);
        },
    },

    created() {
        this.getFormParams();
        Object.entries(this.services).filter(([key,val]) => key !== 'type').forEach(([key, val]) => {
            this.servicesSchema[key].value = val;
        });
    },
};
</script>

<style scoped>
.submit-button-hover:hover {
    background: #C35272 !important;
    transition: 0.1s ease-in;
}
</style>
