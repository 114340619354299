<template>
    <base-card 
     card-height="500px" 
     style="background-color: #eeeeeecf"
    >
        <template #content>
            <slot />
        </template>
    </base-card>
</template>

<script>
import BaseCard from "@/components/base/BaseCard.vue";
export default {
    name: "UserProfileSection",
    props: {},
    components: { BaseCard },

    data() {
        return {};
    },

    computed: {},
    methods: {},
    created() {},
};
</script>

<style scoped></style>
