<template>
    <user-profile-section class="mb-8">
        <v-card flat class="transparent">
            <v-card-title class="accent--text text--darken-3">
                {{ $t(`Profile.publicSection.memberDetails${memberDetails.type == 1 ? 'DIH' : 'SME'}`) }}
            </v-card-title>

            <v-card-text class="pb-0">
                <v-row>
                    <v-col order="2" order-lg="0">
                        <text-input
                            :input-value.sync="username"
                            :current-value="username"
                            :toggle-dense="true"
                            :in-valid.sync="nameInvalid"
                            label="Organization Name"
                        ></text-input>

                        <v-text-field
                            v-model="telephone"
                            v-bind="textFieldClasses"
                            :label="
                                $t('Profile.publicSection.address.telephone')
                            "
                        ></v-text-field>
                        <mail-input
                            :input-value.sync="contact_email[0]"
                            :current-value="contact_email[0]"
                            :in-valid.sync="emailInvalid"
                            :label="
                                $t(
                                    'Profile.publicSection.website.contactPerson'
                                )
                            "
                        ></mail-input>

                        <v-text-field
                            v-model="contact_email[1]"
                            v-bind="textFieldClasses"
                            hint="optional"
                            :label="
                                $t(
                                    'Profile.publicSection.website.contactPersonAdditional'
                                )
                            "
                        >
                        </v-text-field>

                        <text-input
                            :input-value.sync="org_website"
                            :current-value="org_website"
                            :toggle-dense="true"
                            :in-valid.sync="orgWebsiteInvalid"
                            :label="$t('Profile.publicSection.address.website')"
                        ></text-input>

                        <text-input
                            :input-value.sync="skills_website"
                            :current-value="skills_website"
                            :toggle-dense="true"
                            :in-valid.sync="orgWebsiteInvalid"
                            :required-input="false"
                            :label="
                                $t(
                                    'Profile.publicSection.address.skills_website'
                                )
                            "
                        ></text-input>

                        <text-input
                            v-if="memberDetails.userRole === 1"
                            :input-value.sync="smart_website"
                            :current-value="smart_website"
                            :toggle-dense="true"
                            :in-valid.sync="orgWebsiteInvalid"
                            :required-input="false"
                            :label="
                                $t(
                                    'Profile.publicSection.address.smart_specialisation'
                                )
                            "
                        ></text-input>

                        <v-row>
                            <v-col cols="12" md="6" class="pt-3 pb-0">
                                <v-text-field
                                    v-model="facebook_url"
                                    v-bind="textFieldClasses"
                                    prepend-inner-icon="fab fa-facebook"
                                    :label="
                                        $t(
                                            'Profile.publicSection.social.facebook'
                                        )
                                    "
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-3 pb-0">
                                <v-text-field
                                    v-model="twitter_url"
                                    v-bind="textFieldClasses"
                                    prepend-inner-icon="fab fa-twitter"
                                    :label="
                                        $t(
                                            'Profile.publicSection.social.twitter'
                                        )
                                    "
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-3 pb-0">
                                <v-text-field
                                    v-model="linkedin_url"
                                    v-bind="textFieldClasses"
                                    prepend-inner-icon="fab fa-linkedin"
                                    :label="
                                        $t(
                                            'Profile.publicSection.social.linkedin'
                                        )
                                    "
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-3 pb-0">
                                <v-text-field
                                    v-model="youtube_url"
                                    v-bind="textFieldClasses"
                                    prepend-inner-icon="fab fa-youtube"
                                    :label="
                                        $t(
                                            'Profile.publicSection.social.youtube'
                                        )
                                    "
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="justify-between pr-0">
                <div class="flex-grow-1">
                    <ul v-for="(err, idx) in apiData.errors" :key="idx">
                        <li class="accent--text text--darken-2">
                            {{ err }}
                        </li>
                    </ul>
                </div>
                <div
                    v-if="apiData.successMessage"
                    class="flex-grow-1 primary--text"
                >
                    <span>{{ apiData.successMessage }}</span>
                </div>
                <v-btn
                    depressed
                    color="primary"
                    :loading="apiData.pending"
                    :disabled="toggleSaveButton"
                    class="submit-button-hover"
                    @click="updateMemberDetails()"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </user-profile-section>
</template>

<script>
import UserProfileSection from "../userProfile/UserProfileSection.vue";
import { apiStatus } from "../../api/constants/apiStatus";
import { apiStatusComputed } from "../../api/helpers/computedApiStatus";
import { withAsync } from "../../helpers/withAsync";

import SelectInput from "../../components/Inputs/SelectInput.vue";
import TextInput from "../../components/Inputs/TextInput.vue";
import MailInput from "../../components/Inputs/MailInput.vue";

export default {
    name: "UserProfilePublicMemberDetails",

    props: {
        memberDetails: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
        apiData: {
            type: Object,
            required: false,
            default: null,
        },
        textFieldClasses: {
            type: Object,
            required: true,
        },
    },
    components: {
        UserProfileSection,
        SelectInput,
        TextInput,
        MailInput,
    },
    data() {
        return {
            updateUserStatus: apiStatus.Idle,
            getCountriesStatus: apiStatus.Idle,

            countries: [],
            username: this.memberDetails.fullname || null,
            org_website: this.memberDetails.org_website || "https://",
            skills_website: this.memberDetails.skills_website || "https://",
            smart_website: this.memberDetails.smart_website || "https://",
            contact_email: this.memberDetails.email || null,
            telephone: this.memberDetails.telephone || null,
            facebook_url: this.memberDetails.facebook_url || null,
            twitter_url: this.memberDetails.twitter_url || null,
            linkedin_url: this.memberDetails.linkedin_url || null,
            youtube_url: this.memberDetails.youtube_url || null,

            nameInvalid: this.memberDetails.fullname ? false : true,
            emailInvalid: this.memberDetails.email ? false : true,
            orgWebsiteInvalid: this.memberDetails.org_website ? false : true,
        };
    },
    computed: {
        ...apiStatusComputed("updateUserStatus", "getCountriesStatus"),
        toggleSaveButton() {
            if (this.memberDetails) {
                for (let key in this.memberDetails) {
                    if (this.memberDetails.hasOwnProperty(key)) {
                        return this.isValidated();
                    }
                }
                return true;
            }
            return this.isValidated();
        },
    },
    methods: {
        isValidated() {
            if (this.nameInvalid) return true;
            if (this.emailInvalid) return true;
            if (this.orgWebsiteInvalid) return true;
        },
        updateMemberDetails() {
            this.$emit("update-user", {
                organization_fullname: this.username,
                contact_phone: this.telephone,
                contact_email: this.contact_email,
                organization_web_url: this.org_website,
                skills_training_web_url: this.skills_website,
                smart_specialisation_web_url: this.smart_website,
                facebook_url: this.facebook_url,
                twitter_url: this.twitter_url,
                linkedin_url: this.linkedin_url,
                youtube_url: this.youtube_url,
            });
        },
    },
};
</script>

<style scoped>
.submit-button-hover:hover {
    background: #c35272 !important;
    transition: 0.1s ease-in;
}
::v-deep .v-input__prepend-inner {
    margin-top: 0 !important;
}
::v-deep .v-input__icon.v-input__icon--prepend-inner i {
    margin-top: 0;
    color: var(--v-primary-base) !important;
}
::v-deep .v-input__icon.v-input__icon--prepend-inner i:hover {
    color: var(--v-error-base) !important;
}
</style>
